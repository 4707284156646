import { query, request } from '@distancify/drapejs-litium';
import { defineCommand, defineReceiver } from '@drapejs/invoker';
import { gql } from 'graphql-request';

const getReturnToken = gql`
  query getReturnToken($url: String!, $order: String, $delivery: String) {
    session(url: $url) {
      easyComReturnToken(order: $order, delivery: $delivery)
    }
  }
`;

export const commands = {
  getReturnToken: defineCommand<{
    url: string;
    order: string;
    delivery: string;
  }>('getReturnToken'),
};

export const receivers = {
  getReturnToken: defineReceiver(
    commands.getReturnToken,
    async function (command, data) {
      const result = await request(this.cache,
        query(getReturnToken),
        {
          ...command,
        },
      );
      return {
        token: result.session.easyComReturnToken,
      };
    },
    'litium',
  ),
};
